
import cookies from 'js-cookie';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';

import { menu } from '../../router';

import { Icon } from '../../components/ui';
import { useStores } from '@/store/Stores';

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return await response.json();
}

export default {
  components: {
    InputText,
    Password,
    Button,
    Icon
  },

  setup() {
    const { accountStore, accountStoreState } = useStores();

    const router = useRouter();

    const submitted = ref(false);
    const submiting = ref(false);
    const error = ref('');

    const state = reactive({
      email: '',
      password: ''
    });
    const rules = {
      email: { required, email },
      password: { required }
    };

    const v$ = useVuelidate(rules, state);

    const handleSubmit = (isFormValid) => {
      submitted.value = true;
      v$.value.$touch();
      if (!isFormValid) {
        return;
      }
      submiting.value = true;
      postData(`${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOST}/api/auth/login`, state)
        .then((data) => {
          if (data.error) {
            switch (data.error) {
              case 'Unauthorized':
                error.value = 'login_page.unauthorized';
                break;
              default:
                error.value = 'something_went_wrong';
                break;
            }
            return;
          }
          cookies.set('token', data.access_token, { expires: (data.expires_in * 10000) / 8.64e7 });
          cookies.set('user', JSON.stringify(data.user), { expires: (data.expires_in * 10000) / 8.64e7 });
          cookies.set('no-refresh', true, { expires: (data.expires_in * 10000) / 2 / 8.64e7 });
          accountStore.setAccount(data.user);
          router.push(menu[accountStoreState.role][0].to);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          submiting.value = false;
        });
    };

    return {
      v$,
      state,
      error,
      submitted,
      submiting,
      handleSubmit
    };
  }
};
