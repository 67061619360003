import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "_heading-1 mb-2" }
const _hoisted_2 = { class: "_heading-3 mb-5" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3 _error"
}
const _hoisted_4 = { class: "p-label" }
const _hoisted_5 = { class: "p-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Password = _resolveComponent("Password")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('login_page.title')), 1),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('login_page.sub_title')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.handleSubmit(!$setup.v$.$invalid)), ["prevent"]))
    }, [
      ($setup.error)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t($setup.error)), 1))
        : _createCommentVNode("", true),
      _createElementVNode("label", {
        class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$.email.$invalid && $setup.submitted }])
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('email')), 1),
        _createVNode(_component_InputText, {
          type: "email",
          modelValue: $setup.v$.email.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.email.$model) = $event))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("label", {
        class: _normalizeClass(["p-field mb-4", { 'p-invalid': $setup.v$.password.$invalid && $setup.submitted }])
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('password')), 1),
        _createVNode(_component_Password, {
          feedback: false,
          modelValue: $setup.v$.password.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.password.$model) = $event)),
          toggleMask: ""
        }, null, 8, ["modelValue"])
      ], 2),
      _createVNode(_component_router_link, {
        to: { name: 'ForgotPassword' },
        class: "_link _link-secondary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('login_page.forgot_password')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        disabled: $setup.submiting,
        type: "submit",
        class: "_full-width mb-4 p-button-icon-right mt-5"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('login_page.sign_in')), 1),
          _createVNode(_component_Icon, {
            name: "log_in",
            className: "p-field-icon"
          })
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_Button, { class: "p-button-outlined p-button-secondary _full-width" }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('login_page.contact_support')), 1)
        ]),
        _: 1
      })
    ], 32)
  ], 64))
}